<template lang="pug">
om-modal.sync-modal(
  :name="modalName"
  width="600"
  color="light"
  @closeClicked="closeModal"
  @beforeOpen="beforeOpenModal"
)
  template(#header-title)
    h3 {{ $t('feeds.sync.modal.title') }}

  template(#modal-body)
    span {{ $t('feeds.sync.modal.description') }}

  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="closeModal") {{ $t('cancel') }}
      om-button.ml-2.confirm-btn(primary @click="syncFeed" :loading="confirmed") {{ $t('sure') }}
</template>
<script>
  import SYNC_PRODUCT_FEED from '@/graphql/SyncProductFeed.gql';

  export default {
    data: () => ({
      modalName: 'feed-sync-modal',
      id: null,
      confirmed: false,
    }),
    methods: {
      async syncFeed() {
        this.confirmed = true;
        try {
          await this.$apollo.mutate({
            mutation: SYNC_PRODUCT_FEED,
            variables: {
              id: this.id,
            },
          });

          this.$notify({
            type: 'success',
            text: this.$t('feeds.sync.success'),
          });
        } catch (err) {
          this.$notify({
            type: 'error',
            text: this.$t('feeds.sync.failed'),
          });
          console.error('Caugth error during sync feed', { msg: err.message, stack: err.stack });
        } finally {
          this.$bus.$emit('reloadFeedTable');
          this.closeModal();
        }
      },
      closeModal() {
        this.id = null;
        this.confirmed = false;
        this.$modal.hide(this.modalName);
      },
      beforeOpenModal({ params: { id } }) {
        this.id = id;
      },
    },
  };
</script>
